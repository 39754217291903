export const environment = {
  production: true,
  //AWS environment
  BASE_URL: 'https://danielsadmin.augmentes.com/',
  image_base_url: 'https://danielsadmin.augmentes.com/media/catalog/product/',
  aws_S3_Url:'https://arpimages.s3.us-west-1.amazonaws.com/',

  try_on_share_url: 'https://danielsadmin.augmentes.com/danielsfbshare.php?tryOnShareId=',
  social_share_url: 'https://www.facebook.com/sharer/sharer.php?kid_directed_site=0&sdk=joey&u={try_on_share_url}&display=popup&ref=plugin&src=share_button',
  danielUrl:'https://tryon.augmentes.com/',
  webarUrl:'http://dyotryon.augmentes.com/',
  webarAdminAPI:'https://tryonapi.augmentes.com/'
};

