

<ng-container *ngIf="fromParent">

<div class="modal-header" *ngIf="fromParent.showHeader" >
    <h5 class="modal-title">Modal title</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<ng-container *ngIf="!fromParent.showHeader" >
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</ng-container>

<div class="modal-body">
    <div class="" [innerHTML]="fromParent.content"></div>
</div>
<div class="modal-footer" *ngIf="fromParent.showHeader">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>
</div>
</ng-container>