import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LazyLoadImageModule, scrollPreset } from 'ng-lazyload-image';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { TranslateModule } from '@ngx-translate/core';
import introspectionResult from '../../../src/introspection-result';
import {NgxPrintModule} from 'ngx-print';

// Header and Footer Components

// Tap To Top
import { TapToTopComponent } from './components/tap-to-top/tap-to-top.component';

// Pipes
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { SafePipe } from './pipes/safe.pipe';
import { StringReplacePipe } from './pipes/stringreplace.pipe';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { GeneralModalComponent } from './components/modal/general-modal/general-modal.component';
import { OnlyNumberDirective } from './classes/directives/only-number.directive';

@NgModule({
  declarations: [
    TapToTopComponent,
    SafePipe,
    StringReplacePipe,
    SpinnerComponent,
    GeneralModalComponent,
    OnlyNumberDirective
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    LazyLoadImageModule.forRoot({
      // preset: scrollPreset // <-- tell LazyLoadImage that you want to use scrollPreset
    }),
    NgxSkeletonLoaderModule,
    TranslateModule,
    NgxPrintModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    LazyLoadImageModule,
    NgxSkeletonLoaderModule,
    TranslateModule,
    NgxPrintModule,
    TapToTopComponent,
    SafePipe,
    StringReplacePipe,
    SpinnerComponent,
    GeneralModalComponent,
    OnlyNumberDirective,
  ]
})
export class SharedModule { 

}

