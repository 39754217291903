<section class="small-section" >
    <div class="main-wrapper wrapper_02">
      <div class="row">

  <div class="col product-filters nextopia sidebar">
            <div class="collection-filter filter-wrapper"  >
                <!-- side-bar colleps block stat -->
                <div class="collection-mobile-back text-right" (click)="mobileSidebar()">
                  <span  class="filter-back">
                    <i  aria-hidden="true" class="lnr lnr-cross"></i>
                </span>
              </div>
                <div id='nxt-refines' class="sidebar-main"></div>
                                     
            </div>
        </div>
        <div class="collection-content col">
          <div class="page-main-content">
            
            <div class="collection-product-wrapper nextopia" id="products">
                <div class="row">
                  <div class="col-12">
                  
                    <div id="nxt-content-wrap" class="column main"></div>
                  </div>
                </div>
              </div>
           
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- section End -->